.interaction-areas-container {
  &:not(:empty) {
    display: block !important;
  }

  .vp-ia-item .vp-ia-area-marker .vp-ia-marker-main {
    border: unset !important;
  }

  .vp-ia-item .vp-ia-area-marker {
    height: 15px !important;
    width: 15px !important;
    z-index: 1;
  }

  .vp-ia-item.theme-pulsing .vp-ia-marker-shadow {
    border: none !important;
  }

  .vp-ia-item:hover .vp-ia-marker-main:before {
    border: unset !important;
  }

  .vp-ia-item[data-selected='true'] {
    border: rgb(149, 147, 254);
    background-color: rgb(149, 147, 254, 0.6);

    // forcing Safari to properly respect the z-index
    position: relative;
    transform: translateZ(0); // creating a new stacking context
  }

  .vp-ia-widget {
    position: absolute;
    z-index: 99999;
    perspective: 1000px;

    .vp-ia-inner-widget {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      text-align: center;
      align-items: center;
      padding: 1rem;
      border-radius: 0.5rem;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      height: 100%;
      min-height: 8rem;
      min-width: 9rem;
      background-color: #f9fafb;
    }

    .vp-ia-close-widget {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      cursor: pointer;

      &:hover {
        background-color: rgba(145, 146, 150, 0.5);
        border-radius: 0.2rem;
        transition: background-color 0.3s ease;
      }
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes fade-in-fwd {
      0% {
        -webkit-transform: translateZ(-200px);
        transform: translateZ(-200px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
      }
    }

    @keyframes slide-in-blurred-top {
      0% {
        -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
      }
    }

    @keyframes swing-in-top-fwd {
      0% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
      }
      100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
      }
    }

    @keyframes flip-in-diag-2-br {
      0% {
        -webkit-transform: rotate3d(-1, 1, 0, -80deg);
        transform: rotate3d(-1, 1, 0, -80deg);
        opacity: 0;
      }
      100% {
        -webkit-transform: rotate3d(1, 1, 0, 0deg);
        transform: rotate3d(1, 1, 0, 0deg);
        opacity: 1;
      }
    }

    @keyframes tilt-in-top-1 {
      0% {
        -webkit-transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
        transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
        opacity: 0;
      }
      100% {
        -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
        transform: rotateY(0deg) translateY(0) skewY(0deg);
        opacity: 1;
      }
    }

    @keyframes rotate-in-top {
      0% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
      }
      100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
      }
    }

    label {
      font-size: 1.5rem;
    }

    img {
      object-fit: contain;
      overflow: hidden;
      width: 112px;
      height: 112px;
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      text-align: center;
      font-size: 1.5rem;
      color: black;
      padding: 0.2rem 0.75rem;
      height: 2rem;
      box-sizing: content-box;
    }
  }
}
