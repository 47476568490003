:root {
  --primary: #9593fe;
  --contrastHigh: #f9fafb;
  --contrastLow: #d1d6e0;
  --contrastInvert: #000000;
  --surface: #1f242e;
  --surfaceAlt: #14181e;
  --background: #090c0f;
  --backgroundAlt: #000000;
  --secondary: #3d475c;
  --secondaryAlt: #535f7a;

  display: flex;
  justify-content: center;
  place-content: center;
  align-items: center;
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: var(--contrastLow);
  background-color: var(--background);
  overflow: hidden;

  #root {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  height: auto;
}

* {
  box-sizing: border-box;
}

@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px;
  }
}
